<template>
	<div class="mt-3">
		<b-form @submit.prevent="printBarcode">

            <div class="row mb-3">
                <div class="col-sm-5 m-auto">
					<div v-if="isEdit" class="row">
						<div class="col">
							<label for="br-spacing">Line Spacing</label>
							<b-form-input type="number" autofocus v-model="spacing" class="mb-3" id="br-spacing" placeholder="Barcode line spacing"></b-form-input>
						</div>
						<div class="col">
							<label for="br-height">Barcode Height</label>
							<b-form-input type="number" autofocus v-model="height" class="mb-3" id="br-height" placeholder="Barcode height"></b-form-input>
						</div>
						<div class="col">
							<button type="button" @click="isEdit=false" class="edit btn btn-info">Save</button>
						</div>
					</div>
					<div class="small">
						<span class="text-black-50">Barcode spacing:</span> {{spacing || 1}} | 
						<span class="text-black-50">Barcode height:</span> {{height || 75}}px
						<span v-if="!isEdit" class="float-right" title="Change barcode properties" v-b-tooltip.hover>
							<b-icon-pencil-square class="m-1 pointer text-success" @click="isEdit=true"></b-icon-pencil-square>
						</span>
					</div>
					<div class="input-group mb-3">
						<b-form-input type="text" tabindex="-1" autocomplete="off" @keydown.tab="printBarcode" autofocus v-model="text" placeholder="Enter text to generate barcode."></b-form-input>
					</div>
	
					
					<div v-if="print_data">
						<!-- Print Bagcode -->
						<div id="capture">
							<VueBarcode :value="print_data" :height="height || 75" :width="spacing || 1">
								Failed to generate barcode.
							</VueBarcode>
						</div>

						<span @click="printBarcode" class="btn btn-info mt-3">Print Barcode</span>
					</div>

                </div>
            </div>

		</b-form>

	</div>
</template>

<script>

import VueBarcode from 'vue-barcode'

export default {
	name: "BarcodeGenerator",
	title: "Barcode Generator",
	data() {
		return {
			isEdit: false,
			print_data: '',
			text: '',
			spacing: 1,
			height: 70
		}
	},
	components: {
		VueBarcode
	},

	methods: {
		async printBarcode() {
			this.print_data = this.text
			setTimeout(() => {
				const contents = document.getElementById('capture').innerHTML;
				const w=window.open('','','left=0,bottom=0,width=100,height=1,toolbar=0,scrollbars=0,status=0');
				w.document.write(contents);
				w.document.close();
				w.focus();
				w.print();
				w.close();
			}, 500);
		}
	}
}
</script>

<style scoped>
	.edit {margin-top: 30px;}
</style>